import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import DateRangePicker from "@/view/pages/partials/DateRangePicker";
import CreateLeave from "@/view/pages/leave/Create-Or-Update/CreateLeave";
import LeaveKanban from "@/view/pages/leave/LeaveKanban";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "leave-list",
  data: function data() {
    return {
      create_leave_dialog: false,
      exportLoading: false,
      dates: [],
      pageModule: "leave-listing",
      routeAPI: "leave",
      routeName: "leave",
      routeDetailName: "leave.detail",
      status: "all",
      statusList: [],
      moreActions: [
      /*{
        title: "Export Leave(s)",
        action: "export_items",
        divider: false,
        icon: "mdi-database-export"
      },
      {
        title: "Export Current View",
        action: "export_current_view",
        divider: true,
        icon: "mdi-database-export"
      },*/
      {
        title: "Refresh List",
        action: "refresh_list",
        divider: true,
        icon: "mdi-refresh"
      }],
      bulkActions: [{
        title: "Mark as Approved",
        action: "approved",
        icon: "mdi-check-all"
      }, {
        title: "Mark as Rejected",
        action: "rejected",
        icon: "mdi-check-all"
      }]
    };
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      }
    }
  },
  components: {
    PageHeaderCount: PageHeaderCount,
    draggable: draggable,
    CustomStatus: CustomStatus,
    DateRangePicker: DateRangePicker,
    PageTips: PageTips,
    Barcode: Barcode,
    TableActivity: TableActivity,
    ListingTemplate: ListingTemplate,
    ListingFooter: ListingFooter,
    ListingTable: ListingTable,
    ListingHeader: ListingHeader,
    ListingSearchCriteria: ListingSearchCriteria,
    Dialog: Dialog,
    CreateLeave: CreateLeave,
    LeaveKanban: LeaveKanban
  },
  methods: {
    bulkAction: function bulkAction(action) {
      var _this = this;

      switch (action) {
        case "approved":
          _this.updateLeaveStatus(2);

          break;

        case "rejected":
          _this.updateLeaveStatus(3);

          break;
      }
    },
    updateLeaveStatus: function updateLeaveStatus(status) {
      var _this = this;

      _this.dataLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "leave/status",
        data: {
          leave: _this.selectedRows,
          status: status
        }
      }).then(function () {
        _this.getRows();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.dataLoading = false;
      });
    },
    moreAction: function moreAction(action) {
      var _this = this;

      switch (action) {
        case "import_items":
          break;

        case "export_items":
          _this.LeaveExport();

          break;

        case "export_current_view":
          _this.exportCurrentView("leave");

          break;

        case "refresh_list":
          _this.getRows();

          break;
      }
    }
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Leave"
    }]);
  },
  beforeMount: function beforeMount() {
    var _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [{
      headerName: "",
      field: "id",
      sort: null,
      visible: true,
      fixed: true,
      sortable: false,
      checkbox: true,
      order: 0,
      width: "50px"
    }, {
      headerName: "Leave #",
      field: "barcode",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 1,
      width: "200px"
    }, {
      headerName: "Engineer",
      field: "engineer",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 2,
      width: "300px"
    }, {
      headerName: "Dates",
      field: "dates",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 4,
      width: "300px"
    }, {
      headerName: "Status / Leave Type",
      field: "status_type",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 3,
      width: "200px"
    }, {
      headerName: "Created Time",
      child: "created_at",
      field: "added_at",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 6,
      width: "170px"
    }, {
      headerName: "Last Modified Time",
      child: "modified_at",
      field: "updated_at",
      sort: null,
      visible: false,
      fixed: false,
      sortable: false,
      order: 7,
      width: "170px"
    }];

    var defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true
    });

    _this.defaultColShow = defaultColDefs.map(function (col) {
      return col.field;
    });
    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize = window.localStorage.getItem(_this.pageModule) || 10;
  }
};