import _toConsumableArray from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  name: "LeaveKanban",
  data: function data() {
    return {
      dropPlaceholderOptions: {
        className: "lead-drop-preview",
        animationDuration: "50",
        showOnTop: true
      },
      list1: [{
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "Second Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }],
      list2: [{
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "Second Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }],
      list3: [{
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "Second Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }, {
        staff: "Development",
        start_date: "22/04/2022",
        end_date: "22/04/2022",
        reason: "I'll not be able to come office today because I am suffering from a cold or high fever",
        half_day: "First Half"
      }]
    };
  },
  methods: {
    onDrop: function onDrop(collection, dropResult) {
      this[collection] = this.applyDrag(this[collection], dropResult);
    },
    getChildPayload1: function getChildPayload1(index) {
      return this.list1[index];
    },
    getChildPayload2: function getChildPayload2(index) {
      return this.list2[index];
    },
    getChildPayload3: function getChildPayload3(index) {
      return this.list3[index];
    },
    getChildPayload4: function getChildPayload4(index) {
      return this.list4[index];
    },
    getChildPayload5: function getChildPayload5(index) {
      return this.list5[index];
    },
    applyDrag: function applyDrag(arr, dragResult) {
      var removedIndex = dragResult.removedIndex,
          addedIndex = dragResult.addedIndex,
          payload = dragResult.payload;
      if (removedIndex === null && addedIndex === null) return arr;

      var result = _toConsumableArray(arr);

      var itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    }
  },
  components: {
    Container: Container,
    Draggable: Draggable
  }
};